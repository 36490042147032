import { EyeIcon, PencilIcon, PhotographIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import ImageUploading from "react-images-uploading";
import { useState } from "react";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import { gql, useApolloClient } from "@apollo/client";
import axios from "axios";

Modal.setAppElement("#root");

function Logo({ business, image }) {
  const [images, setImages] = useState(image ? [`${CONFIG.GOOGLE_STORAGE_PUBLICURL}/${image}`] : []);
  const [imageModal, setImageModal] = useState({ open: false, image: null });
  const apolloClient = useApolloClient();

  async function onChange(imageList, addUpdateIndex) {
    const { data_url, file } = imageList[addUpdateIndex];

    const extension = file.name.split(".").pop();
    const filename = `business/${business}/${uuidv4()}.${extension}`;

    const signedUrlResponse = await apolloClient.query({
      query: gql`
        query GetSignedUrl($filename: String, $type: String) {
          getSignedUrl(filename: $filename, type: $type)
        }
      `,
      variables: {
        filename,
        type: file.type,
      },
    });

    const signedUrl = signedUrlResponse.data.getSignedUrl;
    const imageBlob = await (await fetch(data_url)).blob();

    try {
      const uploadImageResponse = await axios.put(signedUrl, imageBlob, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
        },
      });

      const saveImageResponse = await apolloClient.mutate({
        mutation: gql`
          mutation SaveBusinessLogo($id: String!, $path: String!) {
            saveBusinessLogo(id: $id, path: $path) {
              id
              hero
            }
          }
        `,
        variables: {
          path: filename,
          id: business
        },
        refetchQueries: ["GetBusiness"],
        onQueryUpdated(observableQuery) {
          return true;
        }
      });

      setImages([imageList[0].data_url]);
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteLogo() {
    const deleteResponse = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteBusinessLogo($id: String!) {
          deleteBusinessLogo(id: $id) {
            id
            logo
          }
        }
      `,
      variables: {
        id: business,
      },
      refetchQueries: ["GetBusiness"],
      onQueryUpdated(observableQuery) {
        return true;
      }
    });

    setImages([]);
    setImageModal({ open: false, image: null });
  }

  return (
    <>
      <div>
        <ImageUploading value={images} maxNumber={1} onChange={onChange} dataURLKey="data_url">
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
          }) => (
            <div className="upload__image-wrapper w-24 h-24 flex items-center">
              {images.length === 0 && (
                <button
                  className="transparent p-0 w-full h-full"
                  onClick={onImageUpload}>
                  <div className="bg-gray rounded w-full h-full flex items-center justify-center">
                    <PhotographIcon className="w-6 h-6" />
                  </div>
                </button>
              )}
              {
                images.length > 0 && (
                  <div className="relative w-full h-full flex justify-center">
                    <div className="flex items-center justify-center">
                      <img src={images[0]} className="rounded" />
                    </div>
                    <div className="absolute bottom-1 w-full">
                      <div className="bg-white rounded-full shadow flex p-2 justify-center mx-2">
                        <button className="transparent p-0" onClick={onImageUpdate}>
                          <PencilIcon className="w-3 h-3" />
                        </button>
                        <div className=" mx-2 bg-gray" style={{ width: "1px" }} />
                        <button
                          className="transparent p-0"
                          onClick={() => {
                            setImageModal({ open: true, image: images[0] });
                          }}
                        >
                          <EyeIcon className="w-3 h-3" />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
          }
        </ImageUploading>
        <div className="text-center font-bold text-xs py-2">Logo</div>
      </div>

      <Modal
        isOpen={imageModal.open}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#00000030",
          },
          content: {
            inset: "unset",
            display: "flex",
            width: "40%",
            minHeight: "20%",
            flexDirection: "column",
            padding: 0,
            backgroundColor: "transparent",
            border: 0,
            borderRadius: 0,
          }
        }}
      >
        <>
          {imageModal.image && (
            <div className="flex flex-1 items-center justify-center">
              <img
                src={imageModal.image}
                alt=""
                style={{ maxHeight: "70vh" }}
              />
            </div>
          )}
          <div className="bg-transparent flex mt-4 justify-center">
            <div className="bg-white rounded">
              <button
                className="mr-3 transparent"
                onClick={() => {
                  setImageModal({ open: false, image: null });
                }}
              >
                <XIcon className="w-6 h-6" />
              </button>

              <button
                className="transparent"
                onClick={deleteLogo}
              >
                <TrashIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

export default Logo;
