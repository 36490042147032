import { ApolloLink } from "@apollo/client";

function logger() {
  const loggerLink = new ApolloLink((operation, forward) => {
    if (CONFIG.APOLLO_DEBUG === false) return forward(operation);

    console.log(`GraphQL Request: ${operation.operationName}`);

    operation.setContext({ start: new Date() });
    return forward(operation).map((response) => {
      const responseTime = new Date() - operation.getContext().start;
      console.log(`GraphQL Response took: ${responseTime}ms`);
      return response;
    });
  });

  return loggerLink;
}

export default logger;
