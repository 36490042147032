import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

const { AUTH0_DOMAIN, AUTH0_CLIENTID, AUTH0_AUDIENCE, AUTH0_SCOPE } = CONFIG;

function Auth0Wrapper({ children }) {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      useRefreshTokens
      scope={AUTH0_SCOPE}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0Wrapper;
