import { get } from "lodash";

import config from "../../../tailwind.config";

function Color(color) {
  const c = get(config, `theme.colors.${color}`, color);
  return c;
}

export default Color;
