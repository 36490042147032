import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "@gcm/assets/img/gcm-bag-detail.png";
import backgroundImg from "@gcm/assets/img/background-horizontal-001.png";
// import registerUser from "@gcm/libs/store/user/register";

// async function _registerUser(user, client) {
//   try {
//     const createUserResponse = await client.mutate({
//       mutation: registerUser,
//       variables: {
//         input: {
//           email: user.email,
//           sub: user.sub,
//           name: user.name,
//           givenName: user.givenName,
//           familyName: user.familyName,
//           nickname: user.nickname,
//           picture: user.picture,
//         },
//       },
//     });

//     return { success: true };
//   } catch (error) {
//     console.log("Unable to create user during registration.", error);
//     return { error: true };
//   }
// }

function Login() {
  const { error, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      if (isAuthenticated) {
        navigate("/dashboard");
        return;
        // TODO should we push user info onto the me query?
        // if (!user["https://gcam.com/registering"]) {
        //   history.push("/dashboard");
        //   return;
        // }

        // TODO if this fails we need to logout user
        // const registerUserResponse = await _registerUser(user, client);
        // if (registerUserResponse.success) return history.push("/dashboard");
      }
    }
    init();
  }, [isAuthenticated]);

  return (
    <div
      className="h-screen w-screen"
      style={{
        background: `url("${backgroundImg}")`,
        backgroundSize: "cover",
      }}
    >
      <div
        className="flex flex-col h-full w-full justify-center items-center"
        style={{ backgroundColor: "rgba(0,0,0,.8)" }}
      >
        <img src={Logo} className="mb-5" />
        <a
          className="text-black py-2 px-6 rounded-full"
          style={{ backgroundColor: "#fff" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            loginWithRedirect();
          }}
        >
          Login
        </a>
      </div>
    </div>
  );
}

export default Login;
