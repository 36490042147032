import React, { useState } from "react";
import { Field, Formik } from "formik";
import { gql, useApolloClient } from "@apollo/client";
import toast from "react-hot-toast";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

function SaveRegion({ data }) {
  const apolloClient = useApolloClient();
  const [map, setMap] = useState(null);

  let paths;
  if (data?.geoJson) {
    paths = JSON.parse(data.geoJson).geometry.coordinates[0].map(coordinate => ({
      lng: coordinate[0],
      lat: coordinate[1]
    }));
  }

  return (
    <div className="px-6">
      <Tabs>
        <TabList>
          <Tab>Form</Tab>
          {data?.id && (
            <Tab>Map</Tab>
          )}
        </TabList>

        <TabPanel>
          <Formik
            initialValues={data || {
              active: true
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (!values.id) {
                try {
                  const region = await apolloClient.mutate({
                    mutation: gql`
                      mutation CreateRegion($input: RegionInput) {
                        createRegion(input: $input) {
                          id
                          name
                          description
                          active
                        }
                      }
                    `,
                    variables: {
                      input: {
                        name: values.name,
                        description: values.description,
                        active: values.active,
                      }
                    },
                    refetchQueries: ["GetRegions"]
                  });
                } catch (err) {
                  console.log(err);
                } finally {
                  toast.success("Region created.");
                  setSubmitting(false);
                }
                return;
              }

              try {
                const region = await apolloClient.mutate({
                  mutation: gql`
                    mutation UpdateRegion($id: String!, $input: RegionInput) {
                      updateRegion(id: $id, input: $input) {
                        id
                        name
                        description
                        active
                      }
                    }
                  `,
                  variables: {
                    id: values.id,
                    input: {
                      name: values.name,
                      description: values.description,
                      active: values.active,
                    }
                  },
                  refetchQueries: ["GetRegions"]
                });
              } catch (err) {
                console.log(err);
              } finally {
                toast.success("Region updated.");
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label>
                    <Field type="checkbox" name="active" />
                    Active
                  </label>
                </div>
                <div className="mb-6">
                  <label htmlFor="name">Name</label>
                  <Field type="text" id="name" name="name" placeholder="Name" className="input text" />
                </div>
                <div className="mb-6">
                  <label htmlFor="description">Description</label>
                  <Field component="textarea" id="description" name="description" className="input text" />
                </div>
                <button className="button" type="submit">Save</button>
              </form>
            )}
          </Formik>
        </TabPanel>

        {data?.id && (
          <TabPanel>
            {data?.geoJson && (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "300px", marginBottom: "20px" }}
                center={paths[0]}
                zoom={7}
                onLoad={(map) => setMap(map)}
                onUnmount={() => setMap(null)}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                { /* Child components, such as markers, info windows, etc. */}
                <Polygon
                  paths={paths} />
              </GoogleMap>
            )}
            <input type="file" onChange={async ({ target }) => {
              const { files: [file] } = target;
              // { validity, files: [file] } }
              const ext = file.name.split('.').pop();
              if (!file || ext !== "json") {
                console.log("bad file");
                return;
              }

              const uploadFileResponse = await apolloClient.mutate({
                mutation: gql`
                  mutation UpdateRegionGeoJson($id: String!, $file: Upload!) {
                    updateRegionGeoJson(id: $id, file: $file) {
                      id
                      geoJson
                    }
                  }
                `,
                variables: {
                  id: data.id,
                  file
                }
              });

              target.value = null;
              toast.success("Region GeoJSON updated.");
            }} />
            <div className="mt-3 text-sm italic mb-3">Select a GeoJSON file to update Region boundry.</div>
          </TabPanel>
        )}
      </Tabs>

    </div>
  )
}

export default SaveRegion;
