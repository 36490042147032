import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { gql, useApolloClient, useQuery, } from "@apollo/client";
import Select from "react-select";
import CreatableSelect from "react-select/creatable"
import { useCallback, useEffect, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/outline";

const schema = Yup.object().shape({
  active: Yup.bool(),
  name: Yup.string().required("Name is required").min(2, "2 characters minimum").max(50),
  category: Yup.string().required("Category is required.").nullable(),
  website: Yup.string()
});

async function UpdateBusiness(id, data, client) {
  const response = await client.mutate({
    mutation: gql`
      mutation UpdateBusiness($id: String!, $input: BusinessInput) {
        updateBusiness(id: $id, input: $input) {
          id
          name
          active
          website
          email
          phone
          experience
          type
          category {
            id
          }
          tags {
            id
          }
        }
      }
    `,
    variables: {
      id,
      input: {
        active: data.active,
        name: data.name,
        category: data.category,
        website: data.website,
        email: data.email,
        phone: data.phone,
        experience: data.experience,
        type: data.type,
        tags: data.tags.map(tag => tag.value),
      }
    },
    refetchQueries: ["GetBusiness", "BusinessListViewData"],
    onQueryUpdated(observableQuery) {
      return true;
    }
  });

  return response;
}

async function CreateBusiness(data, client) {
  const response = await client.mutate({
    mutation: gql`
      mutation CreateBusiness($input: BusinessInput) {
        createBusiness(input: $input) {
          id
          name
          active
          website
          email
          phone
          experience
          type
          category {
            id
          }
          tags {
            id
          }
        }
      }
    `,
    variables: {
      input: {
        active: data.active,
        name: data.name,
        category: data.category,
        website: data.website,
        email: data.email,
        phone: data.phone,
        experience: data.experience,
        type: data.type,
        tags: data.tags.map(tag => tag.value),
      }
    },
    refetchQueries: ["GetBusiness", "BusinessListViewData"],
    onQueryUpdated(observableQuery) {
      return true;
    }
  });

  return response;
}

function BusinessForm({ id, category, data, onSaved, handleClose }) {
  const apolloClient = useApolloClient();
  const selectRef = useRef();

  const [viewState, setViewState] = useState({
    experience: data?.experience || "EATS",
    categories: [],
    tags: [],
    tagsLoading: false,
  });

  const { data: viewData, loading, error, refetch } = useQuery(gql`
    query NewBusinessFormViewData($experience: Experience) {
      businessCategories(experience: $experience) {
        id
        name
      }
      businessTags {
        id
        name
      }
    }
  `, {
    variables: {
      experience: data?.experience || "EATS"
    }
  });

  useEffect(
    () => {
      if (!viewData) return;
      setViewState({
        ...viewState,
        tagsLoading: false,
        categories: viewData.businessCategories.map(category => ({ value: category.id, label: category.name })),
        tags: viewData.businessTags.map(tag => ({ value: tag.id, label: tag.name })),
      });
    },
    [viewData]
  );

  return (
    <div className="relative">
      <div className="sticky left-0 top-0 w-full flex justify-between items-center pl-5 py-3 bg-white z-10">
        <h3>Business</h3>
        <button className={`transparent`} onClick={() => handleClose()}><XIcon className="h-6 w-6" /></button>
      </div>
      <div className="px-6 pb-6">
        <Formik
          initialValues={{
            active: data?.active === undefined ? true : data.active,
            name: data?.name || "",
            experience: data?.experience || "EATS",
            type: data?.type,
            category: category || "",
            tags: data?.tags ? data.tags.map(tag => ({ value: tag.id, label: tag.name })) : [],
            website: data?.website || "",
            email: data?.email || "",
            phone: data?.phone || "",
          }}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values) => {
            let response;

            if (id) {
              response = await UpdateBusiness(id, values, apolloClient);
            } else {
              response = await CreateBusiness(values, apolloClient);
            }
            onSaved();
          }}
        >
          {({ setFieldValue, setValues, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group mb-5">
                <label>
                  <Field name="active" type="checkbox" className="checkbox" />
                  Active
                </label>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Name</label>
                <Field id="name" name="name" placeholder="Business name" className="input text" maxLength={50} />
                <ErrorMessage name="name">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label>Experience</label>
                <div className="form-check flex flex-row">
                  <label className="mr-4">
                    <Field type="radio" name="experience" value="EATS" className="radio" onChange={(e) => {
                      setValues({
                        ...values,
                        experience: "EATS",
                        category: null,
                      });
                      refetch({ experience: "EATS" });
                      // setViewState({ ...viewState, experience: "EATS" });
                      // setFieldValue("category", null);
                    }} />
                    Eats
                  </label>
                  <label>
                    <Field type="radio" name="experience" value="GOLF" className="radio" onChange={(e) => {
                      setValues({
                        ...values,
                        experience: "GOLF",
                        category: null,
                      });
                      refetch({ experience: "GOLF" });
                      // setViewState({ ...viewState, experience: "GOLF" });
                      // setFieldValue("category", null);
                    }} />
                    Golf
                  </label>
                </div>
              </div>
              <div className="form-group mb-5">
                <label>Type</label>
                <div className="form-check flex flex-row">
                  <label className="mr-4">
                    <Field type="checkbox" name="type" value="DEAL" /> Deal
                  </label>
                  <label className="mr-4">
                    <Field type="checkbox" name="type" value="ADVERTISER" /> Advertiser
                  </label>
                </div>
              </div>
              <div className="form-group mb-5">
                <label>Category</label>
                <Select
                  ref={selectRef}
                  className="select"
                  options={viewState.categories}
                  isClearable
                  value={viewState.categories.length > 0 ? viewState.categories.find(option => option.value === values.category) || null : null}
                  onChange={(e) => {
                    setFieldValue("category", e ? e.value : null);
                  }} />
                <ErrorMessage name="category">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label>Tags</label>
                <CreatableSelect
                  className="select"
                  options={viewState.tags}
                  isClearable
                  isMulti
                  isDisabled={viewState.tagsLoading}
                  isLoading={viewState.tagsLoading}
                  onCreateOption={async value => {
                    setViewState({ ...viewState, tagsLoading: true });

                    const createTagResponse = await apolloClient.mutate({
                      mutation: gql`
                        mutation CreateBusinessTag($input: BusinessTagInput) {
                          createBusinessTag(input: $input) {
                            id
                            name
                          }
                        }
                      `,
                      variables: {
                        input: {
                          name: value,
                        },
                      },
                      update(cache, { data: { createBusinessTag } }) {
                        cache.modify({
                          fields: {
                            businessTags(existingTags = []) {
                              const newTagReg = cache.writeFragment({
                                data: createBusinessTag,
                                fragment: gql`
                                  fragment NewBusinessTag on BusinessTag {
                                    id
                                    name
                                  }
                                `
                              });

                              return [...existingTags, newTagReg];
                            }
                          }
                        })
                      }
                    });

                    setFieldValue("tags", [...values.tags, { label: createTagResponse.data.createBusinessTag.name, value: createTagResponse.data.createBusinessTag.id }]);
                  }}
                  value={values.tags}
                  onChange={(e) => {
                    setFieldValue("tags", e);
                  }} />
                <ErrorMessage name="category">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <h3>Contact</h3>
              <div className="w-1/3 h-1 bg-blue mb-5" />

              <div className="form-group mb-5">
                <label htmlFor="website">Website</label>
                <Field id="website" name="website" placeholder="Website URL" className="input text" maxLength={100} />
                <ErrorMessage name="website">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" placeholder="Email Address" className="input text" maxLength={100} />
                <ErrorMessage name="email">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="phone">Phone</label>
                <Field id="phone" name="phone" placeholder="Phone Number" className="input text" maxLength={13} />
                <ErrorMessage name="phone">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <button disabled={isSubmitting || !isValid} type="submit" className={`button ${(isSubmitting || !isValid) && "opacity-75"}`}>Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default BusinessForm;
