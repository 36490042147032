import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { gql, useApolloClient } from "@apollo/client";

const schema = Yup.object().shape({
  active: Yup.bool(),
  name: Yup.string().required("Name is required").min(2, "2 characters minimum").max(50),
  experience: Yup.string().required("Experience is required"),
});

async function createCategory(data, client) {
  const category = await client.mutate({
    mutation: gql`
      mutation CreateBusinessCategory($input: CreateBusinessCategoryInput) {
        createBusinessCategory(input: $input) {
          id
          name
          active
          experience
        }
      }
    `,
    variables: {
      input: {
        active: data.active,
        name: data.name,
        experience: data.experience
      }
    },
    refetchQueries: ["GetBusinessCategories"],
    onQueryUpdated(observableQuery) {
      return true;
    }
  });

  return category;
}

async function updateCategory(id, data, client) {
  const category = await client.mutate({
    mutation: gql`
      mutation UpdateBusinessCategory($id: String!, $input: CreateBusinessCategoryInput) {
        updateBusinessCategory(id: $id, input: $input) {
          id
          name
          active
          experience
        }
      }
    `,
    variables: {
      id,
      input: {
        active: data.active,
        name: data.name,
        experience: data.experience
      }
    },
    refetchQueries: ["GetBusinessCategories"],
    onQueryUpdated(observableQuery) {
      return true;
    }
  });

  return category;
}

function BusinessCategoryForm({ id, data, onSaved, }) {
  const apolloClient = useApolloClient();

  return (
    <>
      <h3 className="mb-3">Business Category</h3>
      <Formik
        initialValues={{
          active: data?.active === undefined ? true : data.active,
          name: data?.name || "",
          experience: data?.experience || "EATS"
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (!id) {
            await createCategory(values, apolloClient);
          } else {
            await updateCategory(id, values, apolloClient);
          }

          onSaved();
        }}>
        {({ errors, touched }) => (
          <Form>
            <div className="form-group mb-5">
              <label>
                <Field name="active" type="checkbox" className="checkbox" />
                Active
              </label>
            </div>
            <div className="form-group mb-5">
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" placeholder="Category name" className="input text" maxLength={50} />
              <ErrorMessage name="name">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
            </div>
            <div className="form-group mb-5">
              <label>Experience</label>
              <div className="form-check">
                <label>
                  <Field type="radio" name="experience" value="EATS" className="radio" />
                  Eats
                </label>
              </div>
              <div className="form-check">
                <label>
                  <Field type="radio" name="experience" value="GOLF" className="radio" />
                  Golf
                </label>
              </div>
            </div>

            <button type="submit" className="button">Save</button>
          </Form>
        )}
      </Formik>
    </>
  );
}

BusinessCategoryForm.defaultProps = {
  id: 0,
}

export default BusinessCategoryForm;
