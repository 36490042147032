import { ChevronRightIcon } from "@heroicons/react/outline";
import { Color } from "@gcm/libs/tailwind";
import { useNavigate } from "react-router-dom";

function DealList({ business, deals, onItemPress }) {
  const navigate = useNavigate();

  if (deals.length === 0) {
    return <div>Add a deal to get started.</div>
  }

  return (
    <div>
      {deals.map((deal, index) => (
        <div
          key={`deal-${deal.id}`}
          className={`py-2 flex justify-between items-center ${index + 1 < deals.length ? "border-b border-coolGray" : ""}`}
        >
          <div>
            {deal.name}
          </div>
          <div>
            <button
              className="transparent"
              onClick={() => {
                navigate(`/business/${business}/deal/${deal.id}`);
              }}
            >
              <ChevronRightIcon className="h-6 w-6" color={Color("gray")} />
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default DealList;

