import React, { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useAds } from "@gcm/components/core/AdContext";
import { useDidMount, useDidUpdate } from "@gcm/libs/hooks";
import { v4 as uuidv4 } from "uuid";
import { useInViewport } from "react-in-viewport";
import { Placeholder } from "rsuite";

function Advertisement({ }) {
  const { track } = useAds();
  const viewportRef = useRef();

  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    viewportRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const type = "ADMIN"
  const [requestAd, { data, loading, error, called }] = useLazyQuery(gql`
    query GetRandomAdvertisement($type: String!, $forceRandom: UUID) {
      randomAdvertisement(type: $type, forceRandom: $forceRandom) {
        id
        url
        type {
          id
          height
          width
        }
        image {
          id
          path
        }
      }
    }
  `, {
    fetchPolicy: "network-only",
  });

  useDidMount(() => {
    requestAd({ variables: { type, forceRandom: uuidv4() } });
  });

  useDidUpdate(() => {
    if (!inViewport) return;

    (async () => {
      await track(data.randomAdvertisement.id, "VIEW");
    })();
  }, [inViewport]);

  function trackClick(e) {
    e.preventDefault();
    try {
      track(data.randomAdvertisement.id, "CLICK").then(() => {

      }).catch(err => {
        console.log(err);
      });
      window.open(data.randomAdvertisement.url, "_blank");
    } catch (err) {
      console.log(err);
    }
  }

  if (error) {
    return null;
  }

  if (loading || !called) {
    return (
      <Placeholder.Graph active />
    )
  }

  return (
    <div ref={viewportRef}>
      <a href="#" onClick={trackClick}>
        <img className="rounded-lg shadow-md" src={`${CONFIG.GOOGLE_STORAGE_PUBLICURL}/${data.randomAdvertisement.image.path}`} />
      </a>
    </div>
  )
}

export default Advertisement;
