
import { createContext, useContext } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

const AdContext = createContext();

function AdProvider({ children }) {
  const apolloClient = useApolloClient();
  const { user } = useAuth0();

  async function _track(id, type) {
    if (!CONFIG.TRACK_IMPRESSIONS) return null;

    try {
      const impression = await apolloClient.mutate({
        mutation: gql`
        mutation TrackImpression($input: AdvertisementImpressionInput) {
          trackAdImpression(input: $input) {
            id
          }
        }
      `,
        variables: {
          input: {
            ad: id,
            type,
            user: user["https://giftcertificatesandmore.com/id"],
            experience: "ADMIN"
          }
        }
      });
    } catch (err) {
      console.log(err)
    }
  }

  async function track(id, type) {
    const result = await _track(id, type);
    return result;
  }

  return (
    <AdContext.Provider value={{
      track
    }}
    >
      {children}
    </AdContext.Provider>
  );
}

export default AdProvider;
export const useAds = () => useContext(AdContext);
