import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import backgroundImg from "@gcm/assets/img/background-horizontal-001.png";

function Unauthorized() {
  const { logout } = useAuth0();

  return (
    <div
      className="h-screen w-screen"
      style={{
        background: `url("${backgroundImg}")`,
      }}
    >
      <div
        className="flex flex-col h-full w-full justify-center items-center"
        style={{ backgroundColor: "rgba(39,135,166,.8)" }}
      >
        <h1 className="text-4xl font-bold text-white mb-4">Unauthorized</h1>
        <a
          href="#"
          className="text-white py-2 px-6 rounded-full"
          style={{ backgroundColor: "rgb(242 149 119)" }}
          onClick={(e) => {
            e.preventDefault();
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </a>
      </div>
    </div>
  );
}

export default Unauthorized;
