import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { useJsApiLoader } from "@react-google-maps/api";

import ProtectedRoute from "@gcm/components/core/ProtectedRoute";
import Layout from "@gcm/components/Layout";
import Login from "@gcm/pages/Login";
import Logout from "@gcm/pages/Logout";

import "intersection-observer";
import "react-sliding-side-panel/lib/index.css";
import "@gcm/styles/gcm.css";

const libraries = ["places"];

function GCM() {
  const { isLoading, error } = useAuth0();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAtnFsgqiqOTcZt0m0WSwHYt-xfy9KJx6M",
    libraries,
  });

  if (isLoading || !isLoaded) {
    return <div>Loading</div>;
  }

  if (error) {
    // TODO add error notification
  }

  return (
    <>
      <Toaster containerStyle={{
        zIndex: 20000
      }} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route path="*" element={<Layout />} />
        </Routes>
      </Router>
    </>
  );
}

export default GCM;
