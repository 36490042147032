import { setContext } from "@apollo/client/link/context";

function auth(getToken) {
  const authLink = new setContext(async (_, context) => {
    let accessToken;
    const { headers, authorizationToken } = context;

    if (authorizationToken) {
      accessToken = authorizationToken;
    } else {
      try {
        accessToken = await getToken();
      } catch (error) {
        console.log(error);
      }
    }

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
        "Access-Control-Allow-Origin": "*",
        "gcm-auth": CONFIG.API_TOKEN
      },
    };
  });

  return authLink;
}

export default auth;
