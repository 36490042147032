import React from "react";
import SlidingPanel from "react-sliding-side-panel";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { PhoneIcon, XIcon } from "@heroicons/react/outline";

import UserForm from "@gcm/pages/Users/components/UserForm";

function UserPanel({ user, isOpen, close }) {
  if (user === null) return null;

  return (
    <SlidingPanel
      type={'right'}
      isOpen={isOpen}
      size={30}
    >
      <div className="flex flex-row justify-between p-6">
        <h2>User</h2>
        <div>
          <button className="transparent" onClick={close}><XIcon className="h-6 w-6" /></button>
        </div>
      </div>
      <div className="px-6">
        <div className="mb-6 grid grid-cols-2 gap-5">
          <div className="flex">
            <PhoneIcon className="h-6 w-6 mr-2" />
            <span>{user.phone.countryCode}</span>
            <span>{user.phone.value}</span>
          </div>
          <div>

          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Info</Tab>
          </TabList>

          <TabPanel>
            <UserForm data={user} />
          </TabPanel>
        </Tabs>
      </div>
    </SlidingPanel>
  )
}

export default UserPanel;
