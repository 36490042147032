
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import Select from "react-select";
import { gql, useApolloClient } from "@apollo/client";
import moment from "moment";

const schema = Yup.object().shape({
  active: Yup.bool(),
  plusEnabled: Yup.bool(),
  locations: Yup.array(),
  name: Yup.string().required("Name is required").min(2, "2 characters minimum").max(50),
  value: Yup.string().required().test(value => /^\d*[\.{1}\d*]\d*$/.test(value)),
  redemptionsPerMonth: Yup.number().required("Per month required").min(1),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
});

function DealForm({ business, data, handleClose, locations, onSaved, refetchQueries }) {
  const apolloClient = useApolloClient();

  const [viewState, setViewState] = useState({
    focusedInput: null,
    locations: locations.map(location => ({ value: location.id, label: location.name })),
  });

  async function CreateDeal(business, data, client) {
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation CreateDeal($business: String!, $input: DealInput) {
          createDeal(business: $business, input: $input) {
            id
            name
            active
            plusEnabled
            value
            redemptionsPerMonth
            description
            disclaimer
            startDate
            endDate
            startTime
            endTime
            locations {
              id
            }
            business {
              id
            }
          }
        }
      `,
        variables: {
          business,
          input: {
            active: data.active,
            plusEnabled: data.plusEnabled,
            name: data.name,
            value: Number(data.value),
            redemptionsPerMonth: Number(data.redemptionsPerMonth),
            description: data.description,
            disclaimer: data.disclaimer,
            startDate: data.startDate ? DateTime.fromJSDate(data.startDate).toFormat("yyyy-MM-dd") : null,
            endDate: data.endDate ? DateTime.fromJSDate(data.endDate).toFormat("yyyy-MM-dd") : null,
            startTime: data.startTime ? `${data.startTime.utc().add("minutes", data.startTime.utcOffset()).format("HH:mm:00Z")}` : null,
            endTime: data.endTime ? `${data.endTime.utc().add("minutes", data.endTime.utcOffset()).format("HH:mm:00Z")}` : null,
            locations: data.locations.map(location => location["value"]),
          }
        },
        refetchQueries: refetchQueries,
        onQueryUpdated(observableQuery) {
          return true;
        }
      });

      return response;
    } catch (err) {
      console.log(err);
      return { error: true }
    }
  }

  async function UpdateDeal(dealId, data, client) {
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation UpdateDeal($id: String!, $input: DealInput) {
          updateDeal(id: $id, input: $input) {
            id
            name
            active
            plusEnabled
            value
            redemptionsPerMonth
            description
            disclaimer
            startDate
            endDate
            startTime
            endTime
            locations {
              id
            }
            business {
              id
            }
          }
        }
      `,
        variables: {
          id: dealId,
          input: {
            active: data.active,
            plusEnabled: data.plusEnabled,
            name: data.name,
            value: Number(data.value),
            redemptionsPerMonth: Number(data.redemptionsPerMonth),
            description: data.description,
            disclaimer: data.disclaimer,
            startDate: data.startDate ? DateTime.fromJSDate(data.startDate).toFormat("yyyy-MM-dd") : null,
            endDate: data.endDate ? DateTime.fromJSDate(data.endDate).toFormat("yyyy-MM-dd") : null,
            startTime: data.startTime ? `${moment.utc(data.startTime).add("minutes", data.startTime.utcOffset()).format("HH:mm:00Z")}` : null,
            endTime: data.endTime ? `${moment.utc(data.endTime).add("minutes", data.endTime.utcOffset()).format("HH:mm:00Z")}` : null,
            locations: data.locations.map(location => location["value"]),
          }
        },
        refetchQueries: refetchQueries,
        onQueryUpdated(observableQuery) {
          return true;
        }
      });

      return response;
    } catch (err) {
      console.log(err);
      return { error: true }
    }
  }

  return (
    <div className="relative">
      <div className="sticky left-0 top-0 w-full flex justify-between items-center pl-5 py-3 bg-white z-10">
        <h3>Deal</h3>
        <button className={`transparent`} onClick={() => handleClose()}><XIcon className="h-6 w-6" /></button>
      </div>
      <div className="px-6 pt-1 pb-6">
        <Formik
          initialValues={{
            active: data?.active === undefined ? true : data.active,
            plusEnabled: data?.plusEnabled === undefined ? true : data.plusEnabled,
            locations: data?.locations ? data.locations.map(location => ({ value: location.id, label: location.name })) : [],
            name: data?.name || "",
            value: data?.value || 0,
            redemptionsPerMonth: data?.redemptionsPerMonth || 1,
            description: data?.description || "",
            disclaimer: data?.disclaimer || "",
            startDate: data?.startDate ? DateTime.fromISO(data.startDate).toJSDate() : null,
            endDate: data?.endDate ? DateTime.fromISO(data.endDate).toJSDate() : null,
            startTime: data?.startTime ? moment(data.startTime).utc() : null,
            endTime: data?.endTime ? moment(data.endTime).utc() : null,
          }}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values) => {
            let response;
            if (data?.id) {
              response = await UpdateDeal(data.id, values, apolloClient);
            } else {
              response = await CreateDeal(business, values, apolloClient);
            }
            if (!response.error) {
              onSaved();
            }
          }}
        >
          {({ setFieldValue, setValues, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group flex mb-5">
                <label className="mr-4">
                  <Field name="active" type="checkbox" className="checkbox" />
                  Active
                </label>

                <label>
                  <Field name="plusEnabled" type="checkbox" className="checkbox" />
                  Plus Enabled
                </label>
              </div>
              <div className="form-group mb-5">
                <label>Locations</label>
                <Select
                  // ref={selectRef}
                  className="select"
                  options={viewState.locations}
                  isMulti
                  isClearable
                  closeMenuOnSelect={false}
                  value={values.locations}
                  onChange={(e) => {
                    setFieldValue("locations", e ? e : []);
                  }} />
                <ErrorMessage name="category">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Name<em>*</em></label>
                <Field id="name" name="name" placeholder="Deal name" className="input text" maxLength={50} />
                <ErrorMessage name="name">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="grid grid-cols-2 space-x-5 mb-5">
                <div className="form-group">
                  <label htmlFor="name">Value<em>*</em></label>
                  <Field id="value" name="value" placeholder="" className="input text" maxLength={6}
                    onKeyPress={(event) => {
                      if (!/^\d*[\.{1}\d*]\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <ErrorMessage name="value">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Per Month<em>*</em></label>
                  <Field id="redemptionsPerMonth" name="redemptionsPerMonth" placeholder="" className="input text" maxLength={2}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <ErrorMessage name="redemptionsPerMonth">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
                </div>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Description</label>
                <Field id="description" name="description" placeholder="" className="input text" as="textarea" />
                <ErrorMessage name="description">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Disclaimer</label>
                <Field id="disclaimer" name="disclaimer" placeholder="" className="input text" as="textarea" />
                <ErrorMessage name="disclaimer">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <h4>Deal Dates</h4>
              <div className="bg-coolGray p-5 mb-5 rounded">
                <div className="flex">
                  <div className="form-group w-1/3 mr-5">
                    <label htmlFor="startDate">Start Date</label>
                    <DatePicker
                      selected={values.startDate}
                      onChange={(date) => {
                        setFieldValue("startDate", date);
                      }}
                      className="datePicker-input"
                    />
                  </div>
                  <div className="form-group w-1/3">
                    <label htmlFor="endDate">End Date</label>
                    <DatePicker
                      selected={values.endDate}
                      onChange={(date) => setFieldValue("endDate", date)}
                      className="datePicker-input"
                    />
                  </div>
                </div>
              </div>

              <h4>Daily Time Limits</h4>
              <div className="bg-coolGray p-5 mb-5 rounded">
                <div className="flex">
                  <div className="form-group w-1/3 mr-5">
                    <label htmlFor="startDate">Start Time</label>
                    <TimePicker
                      defaultValue={values.startTime}
                      showSecond={false}
                      use12Hours
                      minuteStep={15}
                      onChange={(val) => {
                        setFieldValue("startTime", val);
                      }}
                    />
                  </div>
                  <div className="form-group w-1/3">
                    <label htmlFor="endDate">End Time</label>
                    <TimePicker
                      defaultValue={values.endTime}
                      showSecond={false}
                      use12Hours
                      minuteStep={15}
                      onChange={(val) => {
                        setFieldValue("endTime", val);
                      }}
                    />
                  </div>
                </div>
              </div>

              <button disabled={isSubmitting || !isValid} type="submit" className={`button ${(isSubmitting || !isValid) && "opacity-75"}`}>Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

DealForm.defaultProps = {
  locations: [],
  refetchQueries: [],
}

export default DealForm;
