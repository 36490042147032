import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { gql, useApolloClient, useQuery, } from "@apollo/client";
import Select from "react-select";
import { useCallback, useEffect, useRef, useState } from "react";
import GooglePlaces from "@gcm/components/GooglePlaces";
import { XIcon } from "@heroicons/react/outline";

const schema = Yup.object().shape({
  active: Yup.bool(),
  name: Yup.string().required("Name is required").min(2, "2 characters minimum").max(50),
  type: Yup.object().required("Type is required"),
  region: Yup.object().required("Region is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postalCode: Yup.string().required("Postal code is required"),
  lat: Yup.number().required("Lat is required"),
  lng: Yup.number().required("Lng is required"),
  geofenceRadius: Yup.number().required("Radius is required").min(1).max(999),
});

async function UpdateLocation(id, data, client) {
  const response = await client.mutate({
    mutation: gql`
      mutation UpdateLocation($id: String!, $input: LocationInput) {
        updateLocation(id: $id, input: $input) {
          id
          name
          active
          type
          region {
            id
          }
          business {
            id
          }
          address
          city
          state
          postalCode
          website
          email
          phone
          geofenceRadius
        }
      }
    `,
    variables: {
      id,
      input: {
        active: data.active,
        name: data.name,
        type: data.type.value,
        region: data.region.value,
        address: data.address,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        lat: Number(data.lat),
        lng: Number(data.lng),
        website: data.website,
        email: data.email,
        phone: data.phone,
        geofenceRadius: Number(data.geofenceRadius),
      }
    },
    refetchQueries: ["GetBusiness"],
    onQueryUpdated(observableQuery) {
      return true;
    }
  });

  return response;
}

async function CreateLocation(business, data, client) {
  try {
    const response = await client.mutate({
      mutation: gql`
      mutation CreateLocation($business: String!, $input: LocationInput) {
        createLocation(business: $business, input: $input) {
          id
          name
          active
          type
          region {
            id
          }
          business {
            id
          }
          address
          city
          state
          postalCode
          website
          email
          phone
          geofenceRadius
        }
      }
    `,
      variables: {
        business,
        input: {
          active: data.active,
          name: data.name,
          type: data.type.value,
          region: data.region.value,
          address: data.address,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          lat: Number(data.lat),
          lng: Number(data.lng),
          website: data.website,
          email: data.email,
          phone: data.phone,
          geofenceRadius: Number(data.geofenceRadius),
        }
      },
      refetchQueries: ["GetBusiness"],
      onQueryUpdated(observableQuery) {
        return true;
      }
    });

    return response;
  } catch (err) {
    console.log(err);
    return { error: err }
  }
}

function LocationForm({ business, id, data, onSaved, handleClose }) {
  const apolloClient = useApolloClient();
  const selectRef = useRef();
  const typeRef = useRef();

  const [viewState, setViewState] = useState({
    regions: [],
  });

  const { data: viewData, loading, error } = useQuery(gql`
    query LocationFormViewData {
      regions {
        id
        name
      }
    }
  `, {

  });

  useEffect(
    () => {
      if (!viewData) return;
      setViewState({
        ...viewState,
        regions: viewData.regions.map(region => ({ value: region.id, label: region.name }))
      });
    },
    [viewData]
  );

  return (
    <div className="relative">
      <div className="sticky left-0 top-0 w-full flex justify-between items-center pl-5 py-3 bg-white z-10">
        <h3>Location</h3>
        <button className={`transparent`} onClick={() => handleClose()}><XIcon className="h-6 w-6" /></button>
      </div>
      <div className="px-6 pb-6">
        <Formik
          initialValues={{
            active: data?.active === undefined ? true : data.active,
            name: data?.name || "",
            region: data?.region ? { label: data.region.name, value: data.region.id } : "",
            type: data?.type ? { label: `${data.type.charAt(0)}${data.type.slice(1).toLowerCase()}`, value: data.type } : null,
            address: data?.address || "",
            city: data?.city || "",
            state: data?.state || "",
            postalCode: data?.postalCode || "",
            lat: data?.coords.lat || "",
            lng: data?.coords.lng || "",
            website: data?.website || "",
            email: data?.email || "",
            phone: data?.phone || "",
            geofenceRadius: data?.geofenceRadius || 6,
          }}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values) => {
            let response;
            if (data?.id) {
              response = await UpdateLocation(data.id, values, apolloClient);
            } else {
              response = await CreateLocation(business, values, apolloClient);
            }

            if (response.error) return;
            onSaved();
          }}
        >
          {({ setFieldValue, setValues, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group mb-5">
                <label>
                  <Field name="active" type="checkbox" className="checkbox" />
                  Active
                </label>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="name">Name</label>
                <Field id="name" name="name" placeholder="Location name" className="input text" maxLength={50} />
                <ErrorMessage name="name">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="region">Region</label>
                <Select
                  ref={selectRef}
                  className="select"
                  options={viewState.regions}
                  isClearable
                  value={values.region}
                  onChange={(e) => {
                    setFieldValue("region", e ? e : null);
                  }} />
                <ErrorMessage name="region">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="region">Location Type</label>
                <Select
                  ref={typeRef}
                  className="select"
                  options={[
                    { label: "Business", value: "BUSINESS" },
                    { label: "Advertisement", value: "ADVERTISEMENT" },
                  ]}
                  isClearable
                  value={values.type}
                  onChange={(e) => {
                    setFieldValue("type", e ? e : null);
                  }} />
                <ErrorMessage name="type">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <h3>Address</h3>
              <div className="w-1/3 h-1 bg-blue mb-5" />

              <div className="form-group mb-5">
                <GooglePlaces handleSelect={(place) => {
                  setValues({
                    ...values,
                    address: place.address,
                    city: place.city,
                    state: place.state,
                    postalCode: place.postalCode,
                    lat: place.lat,
                    lng: place.lng
                  });
                }} />
              </div>
              <div className="form-group mb-5">
                <label htmlFor="address">Street</label>
                <Field id="address" name="address" placeholder="Street Address" className="input text" maxLength={100} />
                <ErrorMessage name="address">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="grid grid-cols-12 space-x-4 mb-5">
                <div className="col-span-8">
                  <label htmlFor="city">City</label>
                  <Field id="city" name="city" placeholder="City" className="input text" maxLength={100} />
                </div>
                <div className="col-span-4">
                  <label htmlFor="state">State</label>
                  <Field id="state" name="state" placeholder="State" className="input text" maxLength={2} />
                </div>
              </div>
              <div className="grid grid-cols-12 mb-5">
                <div className="col-span-4">
                  <label htmlFor="postalCode">Postal Code</label>
                  <Field id="postalCode" name="postalCode" placeholder="Postal Code" className="input text" maxLength={10} />
                </div>
              </div>
              <div className="grid grid-cols-12 space-x-4 mb-5">
                <div className="col-span-6">
                  <label htmlFor="lat">Lat</label>
                  <Field id="lat" name="lat" placeholder="Latitude" className="input text" maxLength={12} />
                </div>
                <div className="col-span-6">
                  <label htmlFor="lng">Lng</label>
                  <Field id="lng" name="lng" placeholder="Longitude" className="input text" maxLength={12} />
                </div>
              </div>
              <div className="grid grid-cols-12 mb-5">
                <div className="col-span-4">
                  <label className="block" htmlFor="geofenceRadius">
                    Geofence Radius<br />
                    <span className="text-xs italic">*in meters</span>
                  </label>
                  <Field id="geofenceRadius" name="geofenceRadius" placeholder="" className="input text" maxLength={3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <h3>Contact</h3>
              <div className="w-1/3 h-1 bg-blue mb-5" />

              <div className="form-group mb-5">
                <label htmlFor="website">Website</label>
                <Field id="website" name="website" placeholder="Website URL" className="input text" maxLength={100} />
                <ErrorMessage name="website">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="email">Email</label>
                <Field id="email" name="email" placeholder="Email Address" className="input text" maxLength={100} />
                <ErrorMessage name="email">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>
              <div className="form-group mb-5">
                <label htmlFor="phone">Phone</label>
                <Field id="phone" name="phone" placeholder="Phone Number" className="input text" maxLength={13} />
                <ErrorMessage name="phone">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
              </div>

              <button disabled={isSubmitting || !isValid} type="submit" className={`button ${(isSubmitting || !isValid) && "opacity-75"}`}>Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default LocationForm;
