import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

function GooglePlaces(props) {
  const [input, setInput] = useState("");
  const [sessionToken, setSessionToken] = useState();
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    setSessionToken(new window.google.maps.places.AutocompleteSessionToken(uuidv4()));
  }, []);

  useEffect(() => {
    if (!window.google) return;

    if (input === "") {
      if (predictions.length > 0) setPredictions([]);
      return;
    }

    const autocomplete = new window.google.maps.places.AutocompleteService();
    try {
      autocomplete.getPlacePredictions(
        {
          input,
          sessionToken,
          types: [],
        },
        (predictions) => {
          setPredictions(predictions);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [input]);

  function getPlace(event) {
    event.preventDefault();

    const placeId = event.target.getAttribute("place_id");
    const name = event.target.getAttribute("name");
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId }, (result, status) => {
      if (status !== "OK") {
        console.log(status);
        return;
      }

      const { address_components, geometry } = result[0];
      console.log(address_components);
      const address = address_components.reduce((acc, cur) => {
        acc[cur.types[0]] = cur.short_name || cur.long_name;
        return acc;
      }, {});

      const place = {
        name,
        address: `${address.street_number || ""} ${address.sub_premise || address.route || ""}`.trim() || name,
        city: address.locality || "",
        state: address.administrative_area_level_1 || "",
        postalCode: address.postal_code || "",
        lat: geometry.location.lat().toFixed(7),
        lng: geometry.location.lng().toFixed(7),
      };

      props.handleSelect(place);
      setInput("");
    });
  }

  return (
    <>
      <input
        id="place-search"
        className="input text"
        placeholder="Location Search"
        type="text"
        value={input}
        onChange={(e) => setInput(e.currentTarget.value.trimLeft())}
      />
      {predictions && (
        <ul className={`google-place-predictions${predictions.length > 0 ? " open" : ""}`}>
          {predictions.map((prediction, index) => (
            <li
              id={`google_prediction_${prediction.place_id}`}
              key={`google_prediction_${prediction.place_id}`}
              place_id={prediction.place_id}
              name={`${prediction.structured_formatting.main_text || prediction.description}`}
              onClick={(e) => getPlace(e)}
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

GooglePlaces.defaultProps = {
  handleSelect: () => { },
};

export default GooglePlaces;
