import { gql } from "@apollo/client";

export default gql`
  query getAuth0Profile {
    auth0Profile @client {
      email
      email_verified
      family_name
      given_name
      locale
      name
      nickname
      picture
      sub
      updated_at
    }
  }
`;
