import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { persistCache } from "apollo-cache-persist";
import { assign } from "lodash";
import React, { useEffect, useState } from "react";
import { createUploadLink } from "apollo-upload-client";

import authLink from "@gcm/libs/links/auth";
import loggerLink from "@gcm/libs/links/logger";
import cache from "@gcm/libs/store/cache";
import getAuth0Profile from "@gcm/libs/store/user/auth0Profile";

function ApolloContext({ children }) {
  const [apolloClient, setApolloClient] = useState(null);
  const [loggingOut, setLoggingOut] = useState(false);
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  useEffect(() => {
    async function init() {
      // await persistCache({
      //   cache,
      //   storage: window.localStorage,
      // });

      const uploadLink = createUploadLink({
        uri: `${CONFIG.API_URL}/graphql`,
      });

      const link = ApolloLink.from([
        loggerLink(),
        authLink(getAccessTokenSilently),
        uploadLink,
      ]);

      const client = new ApolloClient({
        cache,
        link,
        connectToDevTools: true,
      });

      setApolloClient(client);
    }

    init();
  }, []);

  useEffect(() => {
    if (!apolloClient) return;

    async function authentication() {
      if (!isAuthenticated) {
        setLoggingOut(true);
        await apolloClient.clearStore();

        setTimeout(() => {
          setLoggingOut(false);
        }, 3000);
      } else {
        const auth0Profile = {
          email: "",
          email_verified: false,
          family_name: "",
          given_name: "",
          locale: "",
          name: "",
          nickname: "",
          picture: "",
          sub: "",
          updated_at: null,
        };

        apolloClient.cache.writeQuery({
          query: getAuth0Profile,
          data: {
            auth0Profile: assign(auth0Profile, user),
          },
        });
      }
    }

    authentication();
  }, [isAuthenticated]);

  if (isLoading || !apolloClient || loggingOut) return null;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export default ApolloContext;
