import { PhotographIcon } from "@heroicons/react/outline";

function Gallery() {
  return (
    <div>
      <div className="bg-gray rounded p-10">
        <PhotographIcon className="w-6 h-6" />
      </div>
      <div className="text-center font-bold text-xs py-2">Gallery</div>
    </div>
  )
}

export default Gallery;
