import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { intersection } from "lodash";
import React from "react";
import { Route } from "react-router-dom";

import Unauthorized from "@gcm/components/core/Unauthorized";

const ProtectedRoute = ({ component, roles, ...args }) => {
  const { user, isAuthenticated } = useAuth0();
  let Component = component;

  if (isAuthenticated) {
    if (roles && roles.length > 0) {
      const userRoles = user[`${CONFIG.AUTH0_NAMESPACE}/roles`];
      console.log(userRoles)
      if (intersection(roles, userRoles).length === 0) {
        Component = Unauthorized;
      }
    }
  } else {
    Component = withAuthenticationRequired(component, {
      onRedirecting: () => <div>Redirecting you to the login page...</div>,
    });
  }

  return <Component />
};

export default ProtectedRoute;
