import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "@gcm/assets/img/gcm-bag-detail.png";
// import backgroundImg from "@gcm/assets/img/beach-surfboard.jpg";

function Login() {
  const { isAuthenticated, logout } = useAuth0();
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      logout({ returnTo: `${window.location.origin}` });
    }
    init();
  }, [isAuthenticated]);

  return (
    <div
      className="h-screen w-screen"
      style={{
        // background: `url("${backgroundImg}")`,
        // backgroundSize: "cover",
      }}
    >
      <div
        className="flex flex-col h-full w-full justify-center items-center"
        style={{ backgroundColor: "rgba(39,135,166,.8)" }}
      >
        <img className="w-60 mb-4" src={Logo} alt="GCM" />
      </div>
    </div>
  );
}

export default Login;
