import { FirebaseProvider } from "@gcm/components/Firebase";
import Apollo from "@gcm/components/core/ApolloContext";
import Auth0 from "@gcm/components/core/Auth0Context";
import GCM from "@gcm/components/core/GCM";
import AdProvider from "@gcm/components/core/AdContext";

import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import 'rsuite/dist/rsuite.min.css';

function Application() {
  return (
    <FirebaseProvider>
      <Auth0>
        <Apollo>
          <AdProvider>
            <GCM />
          </AdProvider>
        </Apollo>
      </Auth0>
    </FirebaseProvider>
  );
}

export default Application;
