import React, { useState } from "react";
import { Routes, Route, useRouteMatch, NavLink } from "react-router-dom";
import Logo from "@gcm/assets/img/gcm-bag-detail.png";
import RegionsPage from "@gcm/pages/Regions";
import UsersPage from "@gcm/pages/Users";
import SettingsMenu from "./components/Menu/Settings";
import SlidingPanel from "react-sliding-side-panel";
import BusinessCategoryListPage from "@gcm/pages/Settings/Business/Category/List";
import BusinessListPage from "@gcm/pages/Business/List";
import BusinessDetailPage from "@gcm/pages/Business/Detail";
import DealDetailPage from "@gcm/pages/Deal/Detail";
import LocationDetailPage from "@gcm/pages/Business/Location/Detail";
import ProtectedRoute from "@gcm/components/core/ProtectedRoute";
import Dashboard from "@gcm/pages/Dashboard";
import { Color } from "@gcm/libs/tailwind";
import {
  CalendarIcon,
  CashIcon,
  CogIcon,
  GlobeIcon,
  LocationMarkerIcon,
  UsersIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { useAuth0 } from "@auth0/auth0-react";

function Layout() {
  const { user } = useAuth0();

  const [subMenuState, setSubMenuState] = useState({
    isOpen: false,
    SubMenuComponent: null,
  });

  return (
    <>
      <div className="flex">
        <nav>
          <ul>
            <li className="mt-4 flex flex-row justify-center items-center">
              <img src={Logo} className="w-1/5 mr-3" />
              <span className="text-xl font-bold">GCM</span>
            </li>
            <li>
              <NavLink to="/dashboard">
                <ViewGridAddIcon className="w-4 h-4" color={Color("gray")} />
                <span>Dashboard</span>
              </NavLink>
            </li>
            {user["https://giftcertificatesandmore.com/roles"].includes("ADMIN") && (
              <>
                <li>
                  <NavLink to="/business">
                    <LocationMarkerIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Business</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/users">
                    <UsersIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Users</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/events">
                    <CalendarIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Events</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/advertising">
                    <CashIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Advertising</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/regions">
                    <GlobeIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Regions</span>
                  </NavLink>
                </li>
                <li>
                  <a href="#settings" onClick={(e) => {
                    e.preventDefault();
                    setSubMenuState({ isOpen: true, SubMenuComponent: SettingsMenu })
                  }}>
                    <CogIcon className="w-4 h-4" color={Color("gray")} />
                    <span>Settings</span>
                  </a>
                </li>
              </>
            )}

            <li>
              <span className="text-white">Logout</span>
            </li>
          </ul>
        </nav>
        <main>
          <Routes>
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} roles={["ADMIN", "BUSINESS_MANAGER"]} />} />
            <Route path="/business">
              <Route path=":id" element={<ProtectedRoute component={BusinessDetailPage} roles={["ADMIN"]} />} />
              <Route path=":business/deal/:id" element={<ProtectedRoute component={DealDetailPage} roles={["ADMIN"]} />} />
              <Route path=":business/location/:id" element={<ProtectedRoute component={LocationDetailPage} roles={["ADMIN"]} />} />
              <Route path="/business" element={<ProtectedRoute component={BusinessListPage} roles={["ADMIN"]} />} />
            </Route>
            <Route path="/users" element={<ProtectedRoute component={UsersPage} roles={["ADMIN"]} />} />
            <Route path="/events" element={<h1>Events</h1>} />
            <Route path="/advertising" element={<h1>Advertising</h1>} />
            <Route path="/regions" exact element={<ProtectedRoute component={RegionsPage} roles={["ADMIN"]} />} />
            <Route path="/settings/business/category" exact element={<ProtectedRoute component={BusinessCategoryListPage} roles={["ADMIN"]} />} />
            {/* <Route path="/regions/create" exact component={require("@gcm/pages/Regions/Create").default} /> */}
            {/* <Route path="/region/:id" component={EditRegion} /> */}
            {/* <Route path="/settings" component={() => <h1>Settings</h1>} /> */}
          </Routes>
        </main>

        <SlidingPanel
          type={'right'}
          isOpen={subMenuState.isOpen}
          size={30}
        >
          {
            subMenuState.SubMenuComponent && <subMenuState.SubMenuComponent close={() => setSubMenuState({ isOpen: false, SubMenuComponent: null })} />
          }
        </SlidingPanel>
      </div>
    </>
  )
}

export default Layout;
