import { useEffect, useRef } from 'react';

/**
 * useDidMount hook
 * Calls a function on mount
 *
 * @param {Function} callback Callback function to be called on mount
 */
function useDidMount(callback) {
  const hasMountedRef = useRef(false);
  useEffect(() => {
    if (typeof callback !== "function") return;
    if (hasMountedRef.current) return;
    hasMountedRef.current = true;
    callback();
  }, []);
}

export default useDidMount;
