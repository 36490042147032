module.exports = {
  darkMode: "media",
  content: ["./src/**/*.{html,js}", "./node_modules/tw-elements/dist/js/**/*.js"],
  theme: {
    colors: {
      blue: "#3076CA",
      lightBlue: "#F5F8FC",
      darkBlue: "#09205C",
      gray: "#C1C1C1",
      coolGray: "#F5F5F5",
      warmGray: "#999999",
      black: "#000",
      white: "#fff",
      red: "#EF4444",
      yellow: "#FDE68A",
      green: "#118C4F",
      transparent: "transparent",
    },
    extend: {},
  },
  variants: {
    extend: {},
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/aspect-ratio"),
    require("tw-elements/dist/plugin")
  ],
};
