import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import SlidingPanel from "react-sliding-side-panel";
import { CheckIcon, ChevronRightIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { omit } from "lodash";

import SaveForm from "@gcm/pages/Regions/components/Save";

function Regions() {
  const [saveRegionState, setSaveRegionState] = useState({
    isOpen: false,
    data: null,
  });

  const { data, loading, error } = useQuery(gql`
    query GetRegions {
      regions {
        id
        name
        description
        active
        geoJson
      }
    }
  `);

  function renderTable(regions) {
    if (regions?.length === 0) return (
      <div className="flex w-full">
        <div>
          <h2>Create Your First Region</h2>
          <button onClick={() => {
            setSaveRegionState({ ...saveRegionState, isOpen: true });
          }}>Create Region</button>
        </div>
      </div>
    )

    return (
      <div className="table">
        <div className="row header">
          <div className="col-span-9">Name</div>
          <div className="col-span-2">Active</div>
          <div className="col-span-1"></div>
        </div>
        <div className="body">
          {
            regions.map((region) => {
              return (
                <div key={`region-${region.id}`} className="row cursor-pointer" onClick={() => {
                  setSaveRegionState({
                    ...saveRegionState,
                    isOpen: true,
                    data: omit(region, ["__typename"]),
                  })
                }}>
                  <div className="col-span-9">{region.name}</div>
                  <div className="col-span-2">{region.active ? <CheckIcon className="w-6 h-6" /> : <XIcon className="w-6 h-6" />}</div>
                  <div className="col-span-1 justify-self-end">
                    <ChevronRightIcon className="w-6 h-6" />
                  </div>
                </div>
              )
            })
          }
        </div>
        {/* <div className="row footer">
          <button>PREV</button>
          <button>NEXT</button>
        </div> */}
      </div>
    );
  }

  if (error) return null;
  if (loading) return null;

  const { regions } = data;

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between items-center mb-6">
          <h1>Regions</h1>
          <div>
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setSaveRegionState({ ...saveRegionState, isOpen: true, data: null });
            }}>
              <PlusIcon className="w-6 h-6" />
            </a>
          </div>
        </div>
        <div>
          {renderTable(regions)}
        </div>
      </div>

      <SlidingPanel
        type={'right'}
        isOpen={saveRegionState.isOpen}
        size={30}
      >
        <div className="flex flex-row justify-between p-6">
          <h2>Save Region</h2>
          <div>
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setSaveRegionState({ ...saveRegionState, isOpen: false });
            }}><XIcon className="h-6 w-6" /></a>
          </div>
        </div>
        <SaveForm data={saveRegionState.data} />
      </SlidingPanel>
    </>
  )
};

export default Regions;
