import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAuth0Profile: {
          read(_, props) {
            return {
              auth0Profile: {
                email: props.readField("auth0Profile.email") || "",
                email_verified:
                  props.readField("auth0Profile.email_verified") || false,
                family_name: props.readField("auth0Profile.family_name") || "",
                given_name: props.readField("auth0Profile.given_name") || "",
                locale: props.readField("auth0Profile.locale") || "",
                name: props.readField("auth0Profile.name") || "",
                nickname: props.readField("auth0Profile.nickname") || "",
                picture: props.readField("auth0Profile.picture") || "",
                sub: props.readField("auth0Profile.sub") || "",
                updated_at: props.readField("auth0Profile.update_at") || null,
              },
            };
          },
        },
      },
    },
    UserProfile: {
      fields: {
        name: {
          read(_, props) {
            const firstName = props.readField("firstName") || "";
            const lastName = props.readField("lastName") || "";
            return `${firstName} ${lastName}`;
          }
        }
      }
    },
  },
});

export default cache;
