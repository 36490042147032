import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import SlidingPanel from "react-sliding-side-panel";
import { ChevronRightIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";

import Form from "./Form";

function BusinessCategoriesPage() {
  const [viewState, setViewState] = useState({
    isOpen: false,
    selectedId: 0,
    data: null,
    pagerInfo: {
      sortBy: "name",
      sortDir: "asc",
      pageSize: 10,
    }
  });

  const { data, loading, error } = useQuery(gql`
    query GetBusinessCategories {
      businessCategories {
        id
        name
        active
        experience
      }
    }
  `, {
    fetchPolicy: "network-only"
  });

  function renderBody() {
    const { businessCategories } = data;

    if (businessCategories?.length === 0) return (
      <div className="flex w-full">
        <div>
          <h2>Create Your First Category</h2>
          <button onClick={() => {
            setViewState({ ...viewState, isOpen: true });
          }}>Create Category</button>
        </div>
      </div>
    )

    return renderTable();
  }

  function renderTable() {
    return (
      <div className="table">
        <div className="row header">
          <div className="col-span-8">Name</div>
          <div className="col-span-2">Experience</div>
          <div className="col-span-1" />
          <div className="col-span-1" />
        </div>
        <div className="body">
          {
            data.businessCategories.map((category) => {
              return (
                <div key={`category-${category.id}`} className="row">
                  <div className="col-span-8">
                    {category.name}
                  </div>
                  <div className="col-span-2">
                    {category.experience}
                  </div>
                  <div className="col-span-1 justify-self-end">
                    {category.active
                      ? <CheckCircleIcon className="w-6 h-6 inline-block mr-2" color="green" />
                      : <span className="w-6 h-6" />
                    }
                  </div>
                  <div className="col-span-1 justify-self-end">
                    <button className={`transparent`} onClick={() => {
                      setViewState({
                        ...viewState,
                        isOpen: true,
                        selectedId: category.id,
                        data: {
                          name: category.name,
                          active: category.active,
                          experience: category.experience,
                        }
                      });
                    }}>
                      <ChevronRightIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              )
            })
          }
        </div>
        {data.businessCategories.length >= viewState.pagerInfo.pageSize && (
          <div className="footer">
            <button className={`transparent`}>PREV</button>
            <button className={`transparent`}>NEXT</button>
          </div>
        )}

      </div>
    );
  }

  if (error) return <div>Error</div>;
  if (loading) return <div>Loading</div>;

  return (
    <>
      <div className="p-12">
        <div className="flex justify-between items-center mb-6">
          <h1>Categories</h1>
          <div>
            <a href="/users/create" onClick={(e) => {
              e.preventDefault();
              setViewState({ ...viewState, isOpen: true, data: null });
            }}>
              <PlusIcon className="w-6 h-6" />
            </a>
          </div>
        </div>
        <div>
          {renderBody()}
        </div>
      </div>

      <SlidingPanel
        type={'right'}
        isOpen={viewState.isOpen}
        size={30}
      >
        <>
          <div className="absolute right-6 top-6">
            <button className={`transparent`} onClick={() => setViewState({ ...viewState, isOpen: false })}><XIcon className="h-6 w-6" /></button>
          </div>
          <div className="p-6">
            <Form id={viewState.selectedId} data={viewState.data} onSaved={() => setViewState({ ...viewState, isOpen: false, data: null, selectedId: 0 })} />
          </div>
        </>
      </SlidingPanel>
    </>
  )
}

export default BusinessCategoriesPage;
