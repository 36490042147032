import { gql, useApolloClient } from "@apollo/client";
import { ChartBarIcon, CheckIcon, PlusIcon } from "@heroicons/react/outline";
import { Color } from "@gcm/libs/tailwind";

function LocationList({ deal, business, locations, refetchQueries }) {
  const apolloClient = useApolloClient();

  function renderActionButton(location) {
    const loc = locations.find(l => l.id === location.id);
    if (loc) return (
      <button
        className="transparent px-1 flex"
        onClick={async () => {
          const removeResponse = await apolloClient.mutate({
            mutation: gql`
              mutation RemoveDealLocation($deal: String!, $location: String!) {
                removeDealLocation(deal: $deal, location: $location) {
                  id
                  locations {
                    id
                  }
                }
              }
            `,
            variables: {
              deal,
              location: location.id
            },
            refetchQueries: refetchQueries,
            onQueryUpdated(observableQuery) {
              return true;
            }
          })
        }}
      >
        <div className="border-2 border-blue rounded">
          <CheckIcon className="w-4 h-4" color={Color("blue")} />
        </div>
      </button>
    );

    return (
      <button className="transparent px-1"
        onClick={async () => {
          const addResponse = await apolloClient.mutate({
            mutation: gql`
              mutation AddDealLocation($deal: String!, $location: String!) {
                addDealLocation(deal: $deal, location: $location) {
                  id
                  locations {
                    id
                  }
                }
              }
            `,
            variables: {
              deal,
              location: location.id
            },
            refetchQueries: refetchQueries,
            onQueryUpdated(observableQuery) {
              return true;
            }
          })
        }}
      >
        <div className="border-2 border-coolGray rounded">
          <CheckIcon className="w-4 h-4" color={Color("transparent")} />
        </div>
      </button>
    );
  }

  return (
    <div className="">
      {
        business.locations.length === 0 ? (
          <div>Add a location to enable this deal.</div>
        ) : (
          <>
            {business.locations.map((location, index) => {
              return (
                <div key={`location-${location.id}`} className={`flex justify-between items-center py-2 ${index + 1 < business.locations.length ? "border-b border-coolGray" : ""}`}>
                  <div className="w-1/12 flex justify-start">
                    {renderActionButton(location)}
                  </div>
                  <div className="w-10/12">
                    {location.name}
                  </div>
                  <div className="w-1/12 flex justify-center">
                    <button className="transparent px-1">
                      <ChartBarIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              )
            })
            }
          </>
        )
      }
    </div>
  )
}

LocationList.defaultProps = {
  refetchQueries: [],
}

export default LocationList;
