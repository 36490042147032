import React from "react";
import { ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

function SettingsMenu({ close }) {
  const navigate = useNavigate();

  function handleNavigate(path) {
    close();
    navigate(path);
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center p-6">
        <h2>Settings</h2>
        <div>
          <a href="#close" onClick={(e) => {
            e.preventDefault();
            close();
          }}><XIcon className="w-6 h-6" /></a></div>
      </div>
      <ul className="px-6">
        <li>
          <a href="#"
            className="flex flex-row"
            onClick={(e) => {
              e.preventDefault();
              handleNavigate("/settings/business/category")
            }}>
            <ChevronRightIcon className="w-6 h-6" />
            <span>Business Categories</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SettingsMenu;
