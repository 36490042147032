import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";
import { gql, useApolloClient } from "@apollo/client";
import * as Yup from "yup";

const schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required.").min(2, "2 characters minimum").max(50),
  lastName: Yup.string().required("Last name is required.").min(2, "2 characters minimum").max(50),
  email: Yup.string().email("Invalid email address.").required("Email address is required."),
  active: Yup.bool(),
});

function UserForm({ data }) {
  const apolloClient = useApolloClient();

  if (!data) return null;

  return (
    <Formik
      initialValues={{
        id: data?.id || 0,
        active: data?.active || true,
        firstName: data?.profile.firstName || "",
        lastName: data?.profile.lastName || "",
        email: data?.profile.email || "",
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        // if (!values.id) {
        //   try {
        //     const user = await apolloClient.mutate({
        //       mutation: gql`
        //               mutation UpdateUser($input: RegionInput) {
        //                 createRegion(input: $input) {
        //                   id
        //                   name
        //                   description
        //                   active
        //                 }
        //               }
        //             `,
        //       variables: {
        //         input: {
        //           name: values.name,
        //           description: values.description,
        //           active: values.active,
        //         }
        //       }
        //     });
        //   } catch (err) {
        //     console.log(err);
        //   } finally {
        //     toast.success("Region created.");
        //     setSubmitting(false);
        //   }
        //   return;
        // }

        try {
          const user = await apolloClient.mutate({
            mutation: gql`
              mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
                updateUser(id: $id, input: $input) {
                  id
                  active
                  profile {
                    firstName
                    lastName
                    email
                    emailVerified
                  }
                }
              }
            `,
            variables: {
              id: values.id,
              input: {
                active: values.active,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
              }
            }
          });

          toast.success("User updated.");
        } catch (err) {
          console.log(err);
          toast.error("Error updating user.");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Form>
          <div className="form-group mb-5">
            <label>
              <Field type="checkbox" name="active" />
              Active
            </label>
          </div>
          <div className="form-group mb-5">
            <label htmlFor="firstName">First Name</label>
            <Field type="text" id="firstName" name="firstName" placeholder="First Name" className="input text" />
            <ErrorMessage name="firstName">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
          </div>
          <div className="form-group mb-5">
            <label htmlFor="lastName">Last Name</label>
            <Field type="text" id="lastName" name="lastName" placeholder="Last Name" className="input text" />
            <ErrorMessage name="lastName">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
          </div>
          <div className="form-group mb-5">
            <label htmlFor="email">Email</label>
            <div className="relative">
              <Field type="text" id="email" name="email" placeholder="Email" className="input text" />
              {!data.profile.emailVerified && (
                <BadgeCheckIcon className="w-6 h-full absolute top-0 right-2" color="green" />
              )}
              <ErrorMessage name="email">{msg => <span className="text-red text-sm">{msg}</span>}</ErrorMessage>
            </div>
          </div>
          <button type="submit" className="button">Save</button>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm;
