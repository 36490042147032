import { initializeApp } from "firebase/app";
import React, { createContext, useContext } from "react";
import { getStorage } from "firebase/storage";

const FirebaseContext = createContext({});
const useFirebase = () => useContext(FirebaseContext);

function FirebaseProvider({ children }) {
  const firebaseConfig = {
    apiKey: CONFIG.FIREBASE_APIKEY,
    authDomain: CONFIG.FIREBASE_AUTH_DOMAIN,
    projectId: CONFIG.FIREBASE_PROJECTID,
    storageBucket: CONFIG.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: CONFIG.FIREBASE_MESSAGING_SENDERID,
    appId: CONFIG.FIREBASE_APPID,
    measurementId: CONFIG.FIREBASE_MEASUREMENTID,
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  return (
    <FirebaseContext.Provider value={{ app, storage }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export { FirebaseProvider, FirebaseContext, useFirebase };
