import { gql, useApolloClient, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { CheckCircleIcon, ChevronLeftIcon, LinkIcon, MailIcon, PencilAltIcon, PhoneIcon, XCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { DateTime } from "luxon";
import { Circle, GoogleMap, Marker, withGoogleMap } from "react-google-maps";

import LocationForm from "../../components/LocationForm";

import DealList from "../components/DealList";

const MapComponent = withGoogleMap((props) => {
  const location = props.data;

  return (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: location.coords.lat, lng: location.coords.lng }}
    >
      {props.isMarkerShown && <Marker position={{ lat: location.coords.lat, lng: location.coords.lng }} />}
      <Circle center={{ lat: location.coords.lat, lng: location.coords.lng }} radius={location.geofenceRadius || 100} />
    </GoogleMap>
  );
});

function DealDetailPage() {
  const { business, id } = useParams();

  const [viewState, setViewState] = useState({
    panel: null,
    data: null,
  });

  const { data: viewData, loading, error } = useQuery(gql`
    query LocationDetailViewData($id: UUID!) {
      location(id: $id) {
        id
        name
        active
        type
        address
        city
        state
        postalCode
        email
        website
        phone
        coords
        geofenceRadius
        business {
          id
          name
          deals {
            id
            name
          }
        }
        region {
          id
          name
        }
        deals {
          id
          name
        }
      }
    }
  `, {
    variables: {
      id
    }
  });

  if (loading) return null;
  if (error) return <div>Error</div>;
  if (!viewData?.location) return <div>Not found</div>;

  const { location } = viewData;

  return (
    <>
      <div className="p-12">
        <div className="">
          <Link to={`/business/${business}`} className="flex mb-8 items-center">
            <ChevronLeftIcon className="w-8 h-8 mr-3" />
            <span className="font-bold text-lg">Back to {location.business.name}</span>
          </Link>
        </div>
        <div className="grid grid-cols-12 mb-8">
          <div className="col-span-7">
            <div>
              <div className="flex flex-row items-center text-sm font-bold">
                {location.active ?
                  <><CheckCircleIcon color="green" className="w-6 h-6 mr-1" /> Active</> :
                  <><XCircleIcon color="red" className="w-6 h-6 mr-1" /> Inactive</>
                }
              </div>
              <h1>
                {location.name}
              </h1>
              <div className="flex space-x-2 mb-2">
                <div className="pill">
                  {location.region.name}
                </div>
              </div>
              <div className="flex mb-2 text-sm">
                <div className="flex items-center mr-3">
                  <LinkIcon className="w-4 h-4 mr-2" />
                  {location.website || "Unassigned"}
                </div>
                <div className="flex items-center mr-3">
                  <MailIcon className="w-4 h-4 mr-2" />
                  {location.email || "Unassigned"}
                </div>
                <div className="flex items-center">
                  <PhoneIcon className="w-4 h-4 mr-2" />
                  {location.phone || "Unassigned"}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-5 justify-self-end flex items-center">
            <button
              className="transparent"
              onClick={() => {
                setViewState({
                  ...viewState,
                  panel: "FORM"
                })
              }}
            >
              <PencilAltIcon className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-12 space-x-5">
          <div className="col-span-7">
            <div className="flex items-center mb-3">
              <div className="pill gray mr-2">lat: {location.coords.lat}</div>
              <div className="pill gray mr-2">lng: {location.coords.lng}</div>
              <div className="pill gray mr-2">{location.geofenceRadius} meter geofence</div>
            </div>
            <div className="border border-coolGray rounded-xl shadow-md overflow-hidden mb-5">
              <MapComponent
                data={location}
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
            <div className="grid grid-cols-12 space-x-5 mb-5">
              <div className="col-span-6">
                <div className="border border-coolGray rounded-xl shadow-md overflow-hidden p-4">
                  <h4>Address</h4>
                  <div className="w-1/3 h-1 bg-blue my-2" />
                  <div>{location.address}</div>
                  <div>{location.city}, {location.state} {location.postalCode}</div>
                </div>
              </div>
              <div className="col-span-6">

              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className="border border-coolGray rounded-xl shadow-md p-5">
              <h3 className="flex justify-between items-center mb-3">
                Deals
              </h3>
              <DealList locationId={location.id} deals={location.business.deals} selected={location.deals} refetchQueries={["LocationDetailViewData"]} />
            </div>
          </div>
        </div>
      </div>

      <SlidingPanel
        type={'right'}
        isOpen={viewState.panel !== null}
        size={30}
      >
        {viewState.panel === "FORM" &&
          <LocationForm
            business={business}
            data={location}
            onSaved={() => setViewState({ ...viewState, panel: null, data: null })}
            handleClose={() => setViewState({ ...viewState, panel: null, data: null })}
          />
        }
      </SlidingPanel>
    </>
  )
}

export default DealDetailPage;
